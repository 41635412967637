.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    font-size: 0.9rem;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin-bottom: 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 15px;
  }
  
  .social-links a {
    display: inline-block;
    margin-right: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  .social-links a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #444;
  }
  
  @media (max-width: 600px) {
    .footer-content {
      flex-direction: column;
      align-items: left;
    }
    
    .footer-section {
      margin-bottom: 30px;
    }
  }
  